// initial state
const REQUEST_URL = process.env.VUE_APP_REQUEST_URL;

var LOGIN_URL = REQUEST_URL + 'login';
var LOGOUT_URL = REQUEST_URL + 'logout';
var SIGNUP_TOKEN = '';

import axios from 'axios';
const state = () => ({
  token: localStorage.getItem('token') || '',
  anonToken: SIGNUP_TOKEN
})

// getters
const getters = {}

// actions
const actions = {

  async login(context, credentials) {
    let signature = await this.dispatch("wallet/accountSignature");
    const params = new URLSearchParams()
    params.append('wallet', credentials.wallet.toLowerCase());
    params.append('signature', signature);
   //console.log(LOGIN_URL, 'params');
   //console.log(credentials.wallet.toLowerCase(), 'params');
   //console.log(signature, 'params');
    
    return axios.post(LOGIN_URL, params,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      } 
    })
    .then(async res => {
     //console.log(res.data);
      localStorage.setItem('token', res.data.token)
      context.commit('setToken', res.data.token);
      return true;
    })
    .catch(error => {
      return ({ 'error': error.message });
    }); 
  },
  async verify(context, params) {
    return axios.get(REQUEST_URL + 'user/verify/' + params.wallet_address,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-access-token':  context.rootState.auth.token,
      } 
    })
    .then(async () => {
      //console.log(res.data, 'approved verify');
      return true;
    })
    .catch(error => {
      localStorage.removeItem('token');
      context.commit('setToken', '');
     //console.log(error.message, 'error');
      return {'error': error.message};
    }); 
  },
  async logout(context) {
    return axios.post(LOGOUT_URL, {
      token: context.state.token
    },
      {
        headers: {
          'Authorization': context.state.token
        }
      })
      .then(() => {
        localStorage.removeItem('token');
        context.commit('setToken', '');
        return true;
      })
      .catch(() => {
        // remove it anyway in case there is an error
        localStorage.removeItem('token');
        context.commit('setToken', '');
        return true;
      });

  }
}

// mutations
const mutations = {
  setToken(state, t) {
    state.token = t;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
