<template>
  <ion-page>
    <HeaderContainer />
    <ion-content>
    <FeaturedContainer :featured="featured" :collection="collection" :chain_id="chain_id" />
    <div v-if="$route.name=='home'" style="width:100%; height:auto; background-color: black;">
      <div class="section">
        <ion-grid>
          <ion-row class="ion-align-items-center">
            <ion-col class="ion-padding-top" >
              <h4>The 1of1 Marketplace</h4>
              <p>Get ready for the ultimate NFT buying experience with 1of1, the NFT marketplace, currently in Beta and created by ARTM Technologies. Our one-of-a-kind platform blends cutting-edge technology with luxury brand partnerships, offering exclusive NFT collections that can't be found anywhere else. As a subsidiary of ARTM, we have unparalleled brand accessibility and strategic partnerships in the Automotive, Celebrity, Fashion, Sports, and Gaming industries. And if that wasn't enough, holding ARTM tokens in your wallet can reduce fees by a whopping 50%!</p>
              <p>Our current Beta set of NFT collections has been curated from across the blockchain. Keep an eye on our <a href="/drops">Drops page</a> and our <a href="https://discord.gg/jnfwVRwTEg" target="_blank">ARTM Discord channel</a> for official 1of1 NFT mint and drop announcements. Whether you're a seasoned NFT collector or a newcomer to the world of crypto, 1of1 offers a truly unique and immersive buying experience. So join us on this journey towards the extraordinary digital future and discover a world of exclusive NFT collections that are waiting to be yours. Don't miss out on this incredible opportunity to own a piece of digital art history with 1of1!</p>
            </ion-col>
            
          </ion-row>
        </ion-grid>
      </div>
      <div class="section">
        <ion-grid>
          <ion-row class="ion-align-items-center info">
            <ion-col size="12" size-sm>
              <h4>Luxury Brand NFT Marketplaces</h4>
              <p>At 1of1, we specialize in crafting bespoke NFT marketplaces that offer unparalleled value to luxury brands seeking to differentiate their collections from the crowd. Our approach is highly personalized, as we collaborate closely with each brand to curate a truly world-class user experience.</p>
            </ion-col>
            <ion-col size="12" size-sm>
              <img src="assets/1of1_phone.png" />
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <div class="section">
        <ion-grid>
          <ion-row class="ion-align-items-center">
            <ion-col size="12" size-sm>
              <img src="assets/section2_L_2.jpg" />
            </ion-col>
            <ion-col size="12" size-sm>
              <h4>NFT Marketing &amp; Creation</h4>
              <p>Our 1of1 NFTs set the standard for exceptional quality in the industry. With a full-fledged design team and studio, we create visually stunning 2D & 3D renderings that are unrivaled in their world-class appeal.</p>

              <p>Working closely with luxury brands, we ensure that each artwork we produce is a seamless and precise representation of their brand ethos.</p>

                <p>We take NFT storytelling to new heights by collaborating with top-notch professionals in the arts and music industries.</p>

                  <p>Marketing in the cryptocurrency realm is uncharted territory, and it requires a different approach to reach potential NFT buyers who engage and communicate in various ways online. Our marketing specialists are well-versed in connecting new NFTs with the right audience, maximizing their reach and impact.</p>
              
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      
  </div>
      <FooterContainer />
    </ion-content> 
  </ion-page>
  
</template>

<script>
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/vue";
import { defineComponent } from "vue";
import  HeaderContainer from '@/components/HeaderContainer.vue';
import  FooterContainer from '@/components/FooterContainer.vue';
import  FeaturedContainer from '@/components/FeaturedContainer.vue';

//import { useMeta } from 'vue-meta'

export default defineComponent ({
  name: "HomePage",
  /* setup () {
    useMeta({ 
    description: '1of1 creates custom NFT markeplaces for luxury brands who are looking to have their collections set apart from the rest. We work personally with each brand to develop a world-class user experience.',
    og: {
        title: '1of1 NFT Marketplace',
        description: '1of1 creates custom NFT markeplaces for luxury brands who are looking to have their collections set apart from the rest. We work personally with each brand to develop a world-class user experience.',
        image: [
          'https://1of1.biz/assets/section2_L_2.png',
        ]
      }, })
  }, */
  data(){
    return {
      featured: null,
      collection: null,
      chain_id: 1,
      max_tokens: 4,
      total_supply: 0
    }
  },
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    HeaderContainer,
    FooterContainer,
    FeaturedContainer
  },
  mounted() {
    this.getFeatured();
  },
  methods: {
    async getFeatured() {
     //console.log( this.$route.name, 'this route');
      let response = await this.$store.dispatch("collection/viewFeatured", { category: this.$route.name });
      if (response != undefined) {
        this.collection = response.collectionData;
        this.featured = response.featuredData;
        this.total_supply = response.totalSupply;
        this.getCollectionNfts();
      }
    },
    async getCollectionNfts() {
      this.loading = true;
      /* if(this.$route.name!='home'){
        this.max_tokens = 16;
      }*/
      let random = Math.ceil(Math.random() * this.total_supply);
      let page =  Math.ceil(random/this.max_tokens);
      if(page==0) page = 1;
      await this.$store.dispatch("collection/nfts", {
        id: this.collection.collection_address,
        chain_id: this.chain_id,
        page: page,
        size: this.max_tokens,
        featured: true
      });
    },
  }
});
</script>

<style>
.my-custom-menu {
  --width: 500px;
}


</style>