<template>
  <ion-content style="display:cover;">
    <ion-list>
      <ion-item button v-if="this.$store.state.wallet.selectedAccount != ''" href="/profile">
        <ion-icon
          slot="start"
          style="font-size: 24px"
          :icon="personCircleOutline"
        ></ion-icon
        >Profile</ion-item
      >
      <!--<ion-item href="/profile?tab=favorites">
        <ion-icon
          slot="start"
          style="font-size: 24px"
          :icon="starOutline"
        ></ion-icon
        >Favorites</ion-item
      >
      <ion-item href="/profile?tab=created">
        <ion-icon
          slot="start"
          style="font-size: 24px"
          :icon="gridOutline"
        ></ion-icon
        >Created</ion-item
      >-->
      <ion-item button @click="connectWallet()" v-if="this.$store.state.wallet.selectedAccount == ''">
        <ion-icon
          slot="start"
          style="font-size: 24px"
          :icon="logInOutline"
        ></ion-icon
        >Login/Connect</ion-item
      >
      <ion-item button @click="disconnectWallet()" v-if="this.$store.state.wallet.selectedAccount != ''">
        <ion-icon
          slot="start"
          style="font-size: 24px"
          :icon="logOutOutline"
        ></ion-icon
        >Logout</ion-item
      >
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonIcon,
  IonList, 
  IonContent, 
  IonItem
} from "@ionic/vue";
import { defineComponent } from "vue";
import { walletOutline, personCircleOutline, logOutOutline, logInOutline,starOutline, gridOutline } from "ionicons/icons";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

export default defineComponent({
  name: "ProfileMenuPopover",
  components: {
    IonIcon,
    IonList,
    IonContent,
    IonItem,
  },
  setup() {
    return {
      walletOutline,
      personCircleOutline,
      logOutOutline,
      starOutline, 
      gridOutline,
      logInOutline
    };
  },
  methods: {
    connectWallet() {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: "e0a3681de3704807b7decf022971810f", // required
          },
        },
      };

      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions, // required
      });
      //console.log(web3Modal);
      this.$store.commit("wallet/setWeb3Modal", web3Modal);
      this.$store.dispatch("wallet/connectWallet");
    },
    disconnectWallet() {
      localStorage.removeItem('token');
      this.$store.commit('auth/setToken', '');
      this.$store.dispatch("wallet/walletDisconnect");
      this.$router.push('/');
    },
  },
});
</script>

<style>
.my-custom-menu {
  --width: 500px;
}
</style>