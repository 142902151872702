<template>

    <ion-toolbar>
      <ion-grid style="background-color: black">
        <ion-row>
          <ion-col>
            <img
              src="assets/logo_white@2x.png"
              style="width: 100px; height: auto; margin: 20px 0 0 40px"
            />
            <br /><img
              src="assets/future_luxury.png"
              style="width: 100px; height: auto; margin: 20px 0 0 40px"
            />
          </ion-col>
          <ion-col>
            <div style="float: right; margin-right: 20px; text-align: center">
              <p>Follow Us On:</p>
              <a href="https://twitter.com/1of1metaverse" target="_blank">
                <img
                  src="/assets/follow_tw.png"
                  alt=""
                  style="float: right"
                  height="30"
                />
              </a>
              <a href="https://instagram.com/1of1metaverse" target="_blank">
                <img
                  src="/assets/follow_ig.png"
                  alt=""
                  style="float: right; margin-right: 7px"
                  height="30"
                />
              </a>
              <a href="https://www.facebook.com/1of1metaverse" target="_blank">
                <img
                  src="/assets/follow_fb.png"
                  alt=""
                  style="float: right; margin-right: 7px"
                  height="30"
              /></a>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div
              style="
                display: block;
                width: 100%;
                height: 1px;
                background-color: white;
              "
            ></div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <p
              style="
                margin: 10px 0 10px 10px;
                font-size: 0.9em;
                color: rgba(255, 255, 255, 1);
              "
            >
              All rights reserved <strong>2023 1of1&trade; Technologies</strong>
            </p>
          </ion-col>
          <ion-col>
            <div style="float: right">
              <p>Powered By</p>
              <br />
              <a href="https://artmtoken.com" target="_blank"
                ><img
                  src="/assets/Logo_H.svg"
                  alt=""
                  width="100"
                  height="90"
                  style="margin-top: -60px"
              /></a>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>

</template>

<script>
import {  IonToolbar, IonGrid, IonRow, IonCol } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterContainer",
  components: {
    IonToolbar,
    
    IonGrid,
    IonRow,
    IonCol,
  },
});
</script>
