import Vuex from 'vuex'

import auth from './modules/auth'
import wallet from './modules/wallet'
import collection from './modules/collection'
import user from './modules/user'
import order from './modules/order'

// import createLogger from '../../../src/plugins/logger'

// const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    wallet,
    collection,
    user,
    order
  }
  // strict: debug,
  // plugins: debug ? [createLogger()] : []
})
