<template>
  <ion-header>
    <div v-if="this.$store.state.wallet.chainId!=1 && this.$store.state.wallet.chainId!='' " style="height: auto; width: 100%; padding:8px 8px 8px 28px; background-color: lightgray">
     You're viewing data from the main network, but your wallet is connected to the test network ({{ this.$store.state.wallet.chainData }}). To use 1of1, please switch to <a href="https://testnet.1of1.biz" target="_blank">testnet.1of1.biz</a>
    </div>
    <ion-toolbar class="toolbar">
      <a href="/" slot="start"
        ><img src="/assets/icon/logo_black@3x.png" class="title-icon"
      /></a>
      <ion-searchbar @input="searchData($event.target.value)" @click="checkData($event.target.value)" v-if="!isPlatform('mobile')" slot="start" class="site-searchbar" placeholder="Search NFTs and collections" show-cancel-button="focus">
        </ion-searchbar>
      <ion-buttons :class="showButtonClass(isPlatform('mobile'))" style="padding-right: 30px" slot="end">
        <ion-button href="explore"
          ><span class="menu-button-text">Explore</span></ion-button
        >
        <ion-button href="drops"
          ><span class="menu-button-text">Drops</span></ion-button
        >
        <ion-button href="create"
          ><span class="menu-button-text">Creators</span></ion-button
        >
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-menu-button id="menu1" class="menu-slider" menu="first" auto-hide="true"></ion-menu-button>
        <ion-button id="profileMenu" @mouseover="openPopover($event)"><ion-icon style="font-size:24px" :icon="personCircleOutline"></ion-icon></ion-button>
        <ion-menu-button id="menu2" menu="wallet-menu" auto-hide="true"><ion-icon style="font-size:24px" :icon="walletOutline"></ion-icon></ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <div class="navigation-bar"> 
    <ion-buttons style="overflow-y:hidden;">
        <ion-button class="buttons" href="auto"
          ><span class="menu-button-text">Auto</span></ion-button>
        <ion-button href="gaming"
          ><span class="menu-button-text">Gaming</span></ion-button>
        <ion-button href="avatar"
          ><span class="menu-button-text">Avatars</span></ion-button>
        <ion-button href="sports"
          ><span class="menu-button-text">Sports</span></ion-button>
        <ion-button href="art"
          ><span class="menu-button-text">Art/Photography</span></ion-button>
      </ion-buttons></div>
    <ion-searchbar @clear="clearData($event)" @input="searchData($event.target.value)" v-if="isPlatform('mobile')" slot="start" placeholder="Search NFTs and collections" show-cancel-button="focus">
    </ion-searchbar>
    <div :class="resultsClass()">
    <ion-list v-if="results!=undefined"  >
     <ion-item-group v-show="results.collections.length>0">
      <ion-item-divider>
        <ion-label>
          Collections
        </ion-label>
      </ion-item-divider>
      <ion-item button @click="goToCollection(result)" v-for="result in results.collections" :key="result">
        <ion-thumbnail slot="start">
          <img v-if="result.cdn_image!=undefined" :src="result.cdn_image" />
        </ion-thumbnail>
        <ion-label>
          <h2>{{result.name}}</h2>
          <h3>{{result.collection_address}}</h3>
          <p>Total: {{ result.collection_items }}</p>
        </ion-label>
      </ion-item>
     </ion-item-group>
     <ion-item-group v-show="results.tokens.length>0">
      <ion-item-divider>
        <ion-label>
          Tokens
        </ion-label>
      </ion-item-divider>
      <ion-item @click="goToNft(result)" button v-for="result in results.tokens" :key="result">
        <ion-thumbnail style="--border-radius: 10px;" slot="start">
          <img :src="result.cdn_image" />
        </ion-thumbnail>
        <ion-label>
          <h2>{{result.token_metadata.name}}</h2>
          <h3>{{result.token_metadata.description}}</h3>
          <!--<p></p>-->
        </ion-label>
      </ion-item>
     </ion-item-group>
     <ion-item-group v-show="results.users.length>0">
      <ion-item-divider>
        <ion-label>
          Users
        </ion-label>
      </ion-item-divider>
      <ion-item @click="goToUser(result)" button v-for="result in results.users" :key="result">
        <ion-avatar slot="start">
          <img :src="result.profile_pic" />
        </ion-avatar>
        <ion-label>
          <h2>{{result.user_name}}</h2>
          <h3>{{result.wallet_address}}</h3>
          <!--<p></p>-->
        </ion-label>
      </ion-item>
     </ion-item-group>
    </ion-list>
    </div>
</template>

<script>
import {
  IonMenuButton,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonButton,
  IonIcon,
  popoverController,
  IonSearchbar,
  isPlatform,
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  IonItemGroup,
  IonThumbnail,
  IonItemDivider
} from "@ionic/vue";
import { defineComponent } from "vue";
import { walletOutline, personCircleOutline } from "ionicons/icons"; 
import ProfileMenuPopover from  '@/components/ProfileMenuPopover.vue'


export default defineComponent({
  name: "HeaderContainer",
  components: {
    IonMenuButton,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonButton,
    IonIcon,
    IonSearchbar,
    IonList,
    IonItem,
    IonLabel,
    IonAvatar,
    IonItemGroup,
    IonThumbnail,
    IonItemDivider
  },
  setup() {
    return {
      walletOutline,
      personCircleOutline,
      isPlatform
    };
  },
  methods: {
    connectWallet(){
      //this.$store.dispatch("wallet/connectWallet");
    },
    async searchData(searchData){
     //console.log(searchData, 'searching');
      let response = await this.$store.dispatch("collection/search", {
        search: searchData
      });
      //console.log(response);
      if (response != undefined) {
       //console.log(response,"received search");
      }
    },
    async openPopover(ev) {
      const popover = await popoverController
      .create({
        component: ProfileMenuPopover,
        cssClass: 'my-custom-class',
        event: ev,
        showBackdrop: false,
        animated: false,
        translucent: false,
        componentProps: {
          name: 'Options',
        }
      })
      await popover.present();

      const { role } = await popover.onDidDismiss();
      console.log('onDidDismiss resolved with role', role); 
    },
    goToNft(nft){
      this.$store.commit('collection/setSearchResults', null);
       this.$router.push('/nft/' + nft.collection_data[0].chain_id + '/' +  nft.collection_data[0].collection_address + '/' + nft.token_id);
    },
    goToCollection(collection){
      this.$store.commit('collection/setSearchResults', null);
      this.$router.push('/collection/' + collection.chain_id + '/' + collection.collection_address);
    },
    goToUser(user){
      this.$store.commit('collection/setSearchResults', null);
      this.$router.push('/user/' + user.wallet_address);
    },
    async checkData(data){
      //console.log(data, 'clearing data');
      if(data==undefined){
       this.$store.commit('collection/setSearchResults', null);
      }
    },
    resultsClass(){
      let listClass = 'results-list';
      if(isPlatform('mobile')){
        listClass = 'results-list-mobile';
      }
      return listClass;
    },
    showButtonClass(mobile){
      let menuButtonClass = 'menu-buttons';
      if(mobile){
        menuButtonClass = 'mobile-menu-buttons';
      }
      return menuButtonClass;
    }
    
  },
  computed: {
    results() {
      if(this.$store.state.collection.searchData!=undefined){
       //console.log(this.$store.state.collection.searchData, 'found results');
        let results = this.$store.state.collection.searchData;
        if(results.collections.length>0 || results.tokens.length>0 || results.users.length>0){
          return this.$store.state.collection.searchData;
        }else{
          return null;
        }
        
      }else{
       //console.log('search blank');
        return null;
      }
    },
  }
});
</script>

<style>
.my-custom-menu {
  --width: 500px;
}
</style>