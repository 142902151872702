// initial state
const REQUEST_URL = process.env.VUE_APP_REQUEST_URL;

import axios from 'axios';

const state = () => ({
  profileData:[],
  myProfileData:[],
})

// getters
const getters = {}

// actions
const actions = {
  async view(context, params) {
    return axios.get(REQUEST_URL + 'user/profile/' + params.wallet_address,
    { 
      headers: {
        'x-access-token':  context.rootState.auth.token 
      }
    })
    .then(res => {
      context.commit('setMyProfile', res.data);
     //console.log(res.data);
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL + 'user/profile/' + params.wallet_address});
    });
  },
  async update(context, params) {
    const formData = new URLSearchParams()
    if(params.user_name!=null) formData.append('user_name', params.user_name);
    if(params.bio!=null) formData.append('bio', params.bio);
    if(params.email!=null) formData.append('email', params.email);
    if(params.twitter!=null) formData.append('twitter', params.twitter);
    if(params.insta!=null) formData.append('insta', params.insta);
    if(params.web!=null) formData.append('web', params.web);
    formData.append('wallet_address', params.wallet_address);
    
    return axios.post(REQUEST_URL + 'user/profile', formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-access-token':  context.rootState.auth.token,
        }
    })
    .then(res => {
      context.commit('setMyProfile', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async updatePhoto(context, params) {
    if(params.file!=null){
      // const blob = await fetch(base64).then(res => res.blob());
      const formData = new FormData();
      formData.append('wallet_address', params.wallet_address);
      formData.append('type', params.type);
      formData.append('photo', params.file);

      return axios.post(REQUEST_URL + "user/updatephoto",
        formData,
      {
        headers: {
          'x-access-token':  context.rootState.auth.token,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        context.commit('setMyProfile', res.data);
        // return res;
      })
        .catch(error => {
          return({'error': error.message});
        });
    }
  }
}

// mutations
const mutations = {
  setProfile(state, response) {
    if(response.profile_pic!=null) response.profile_pic = response.profile_pic + '?' + Math.random();
    if(response.banner_pic!=null) response.banner_pic = response.banner_pic + '?' + Math.random();
    state.profileData = response;
  },
  setMyProfile(state, response) {
    if(response.profile_pic!=null) response.profile_pic = response.profile_pic + '?' + Math.random();
    if(response.banner_pic!=null) response.banner_pic = response.banner_pic + '?' + Math.random();
    state.myProfileData = response;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
