<template>
  <ion-app>
    <ion-menu side="start" menu-id="first" content-id="main">
      <ion-header class="header">
        <ion-toolbar color="dark">
          <ion-title>1of1</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item href="/">
            <ion-icon
              slot="start"
              style="font-size: 24px"
              :icon="homeOutline"
            ></ion-icon
            >Home</ion-item
          >
          <ion-item href="explore">
            <ion-icon
              slot="start"
              style="font-size: 24px"
              :icon="globeOutline"
            ></ion-icon
            >Explore</ion-item
          >
          <ion-item href="drops">
            <ion-icon
              slot="start"
              style="font-size: 24px"
              :icon="planetOutline"
            ></ion-icon
            >Drops</ion-item
          >
          <ion-item href="auto">
            <ion-icon
              slot="start"
              style="font-size: 24px"
              :icon="carSportOutline"
            ></ion-icon
            >Auto</ion-item
          >
        <ion-item href="gaming">
          <ion-icon
              slot="start"
              style="font-size: 24px"
              :icon="gameControllerOutline"
            ></ion-icon
            >Gaming</ion-item
          >
        <ion-item href="avatar">
          <ion-icon
              slot="start"
              style="font-size: 24px"
              :icon="happyOutline"
            ></ion-icon
            >Avatars</ion-item
          >
        <ion-item href="sports">
          <ion-icon
              slot="start"
              style="font-size: 24px"
              :icon="basketballOutline"
            ></ion-icon
            >Sports</ion-item
          >
        <ion-item href="art">
          <ion-icon
              slot="start"
              style="font-size: 24px"
              :icon="imagesOutline"
            ></ion-icon
            >Art/Photography</ion-item>
        <ion-item href="create">
            <ion-icon
              slot="start"
              style="font-size: 24px"
              :icon="bulbOutline"
            ></ion-icon
            >Creators</ion-item
          >
          <ion-item href="#" @click="connectWallet()">
            <ion-icon
              slot="start"
              style="font-size: 24px"
              :icon="walletOutline"
            ></ion-icon
            >Connect Wallet
          </ion-item>
          
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-menu side="end" menu-id="wallet-menu" content-id="main">
      <ion-header class="header">
        <ion-toolbar color="light">
          <ion-title>My Wallet</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list v-if="this.$store.state.wallet.selectedAccount == ''">
          <ion-item href="#" @click="connectWallet()">
            <ion-icon
              slot="start"
              style="font-size: 24px"
              :icon="walletOutline"
            ></ion-icon
            >Connect My Wallet</ion-item
          >
        </ion-list>
        <ion-list v-if="this.$store.state.wallet.selectedAccount != ''">
          <ion-item>
            <ion-chip>{{ network }}</ion-chip>
            <div slot="end">{{ wallet }}</div>
          </ion-item>
          <ion-item>
            {{ ethBalance }}
            <div slot="end">ETH</div>
          </ion-item>
          <ion-item>
            {{ artmBalance }}
            <div slot="end">ARTM</div>
          </ion-item>
          <ion-item>
            {{ wethBalance }}
            <div slot="end">wETH</div>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main" />
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
  IonHeader,
  IonItem,
  IonTitle,
  IonToolbar,
  IonList,
  IonMenu,
  IonContent,
  IonChip,
  IonIcon,
  popoverController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
  walletOutline,
  homeOutline,
  globeOutline,
  planetOutline,
  bulbOutline,
  carSportOutline,
  basketballOutline,
  gameControllerOutline,
  imagesOutline,
  happyOutline
} from "ionicons/icons";
import ProfileMenuPopover from "@/components/ProfileMenuPopover.vue";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
//import { useMeta } from 'vue-meta'

export default defineComponent({
  name: "App",
  setup() {
    /* useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })*/
    return {
      walletOutline,
      homeOutline,
      globeOutline,
      planetOutline,
      bulbOutline,
      carSportOutline,
      basketballOutline,
      gameControllerOutline,
      imagesOutline,
      happyOutline
    };
  },
  components: {
    IonApp,
    IonRouterOutlet,
    IonHeader,
    IonItem,
    IonList,
    IonMenu,
    IonTitle,
    IonToolbar,
    IonContent,
    IonChip,
    IonIcon,
  },
  data() {
    return {
      provider: null,
    };
  },
  beforeCreate() {
		//this.$store.commit('wallet/initWeb3Modal');
	},
  async mounted () {
    //console.log(this.$store.state.wallet.selectedAccount,'account');
    if(this.$store.state.wallet.selectedAccount!=''){
      //console.log('found');
      this.connectWallet();
    }
  },
  methods: {
    async connectWallet() {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: "e0a3681de3704807b7decf022971810f", // required
          },
        },
      };

      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions, // required
      });
      //console.log(web3Modal);
      this.$store.commit("wallet/setWeb3Modal", web3Modal);
      this.$store.dispatch("wallet/connectWallet");
    },
    async openPopover(ev) {
      const popover = await popoverController.create({
        component: ProfileMenuPopover,
        cssClass: "my-custom-class",
        event: ev,
        showBackdrop: false,
        animated: false,
        translucent: false,
        componentProps: {
          name: "ProfileMenu",
        },
      });
      await popover.present();

      const { role } = await popover.onDidDismiss();
      /* if(role=="view-profile"){
       //console.log('view profile');
      }else if(role== "view-favorites"){
       //console.log('view favorites');
      }else if(role=="logout"){
       //console.log('logout');      
      }*/
     console.log("onDidDismiss resolved with role", role);
    },
  },
  computed: {
    wallet() {
      return this.$store.state.wallet.shortAddress;
    },
    ethBalance() {
      return this.$store.state.wallet.ethBalance;
    },
    artmBalance() {
      return this.$store.state.wallet.artmBalance;
    },
    wethBalance() {
      return this.$store.state.wallet.wethBalance;
    },
    network() {
      return this.$store.state.wallet.chainData;
    },
  },
  
});
</script>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
</style>