import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue'

const routes = [
  {
    path: '/nft/:chain_id/:collection_id/:token_id',
    name: 'nft',
    component: () => import('@/views/NftDetail.vue'),
  },
  {
    path: '/collection/:chain_id/:collection_id',
    name: 'collection',
    component: () => import('@/views/CollectionScreen.vue'),
  },
  {
    path: '/explore',
    name: 'explore',
    component: () => import('@/views/ExploreScreen.vue')
  },
  /*{
    path: '/metaverse',
    name: 'metaverse',
    component: () => import('@/views/MetaVerse.vue')
  },
  {
    path: '/rank',
    name: 'rank',
    component: () => import('@/views/RankScreen.vue')
  },*/
  {
    path: '/create',
    name: 'create',
    component: () => import('@/views/CreateScreen.vue')
  },
  /* {
    path: '/help',
    name: 'help',
    component: () => import('@/views/HelpScreen.vue')
  }, */
  {
    path: '/drops',
    name: 'drops',
    component: () => import('@/views/DropsPage.vue')
  },
  /* {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutUs.vue')
  }, */
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/ProfileScreen.vue')
  },
  {
    path: '/user/:wallet_address',
    name: 'user',
    component: () => import('@/views/ProfileScreen.vue')
  },
  {
    path: '/auto',
    name: 'auto',
    component: HomePage
  },
  {
    path: '/gaming',
    name: 'gaming',
    component: HomePage
  },
  {
    path: '/sports',
    name: 'sports',
    component: HomePage
  },
  {
    path: '/avatar',
    name: 'avatar',
    component: HomePage
  },
  {
    path: '/art',
    name: 'art',
    component: HomePage
  },
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/404',
    name: 'PageNotExist',
    component: () => import('@/views/NotFound.vue'),
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/404',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
