// initial state
const REQUEST_URL = process.env.VUE_APP_REQUEST_URL;

import axios from 'axios';

const state = () => ({
  responseData:[],
  collectionData:null,
  collectionStatsData: null,
  collectionAttsData: null,
  nftData:[],
  nftSingle:null,
  searchData:null,
})

// getters
const getters = {}

// actions
const actions = {
  async all(context, params) {
    var url = 'collection/get_collections';
    if(params.page!=null){
      url = 'collection/get_collections?page=' + params.page + '&size=50';
    }
    return axios.get(REQUEST_URL + url)
    .then(res => {
      context.commit('setRequest', res.data.collections);
      /* if(params.page == 1){
        context.commit('addToRequest', res.data);
      }else{
        context.commit('setRequest', res.data);
      } */
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    }).then(function () {
      // always executed
      return({'error': REQUEST_URL + 'collection/get_collections'});
    });
  },
  async viewDetail(context, params) {
    var url = 'collection/view/' + params.chain_id + '/' + params.id;
    
    return axios.get(REQUEST_URL + url)
    .then(res => {
      context.commit('setCollection', res.data);
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async stats(context, params) {
    var url = 'collection/stats/' + params.chain_id + '/' + params.id;
    
    return axios.get(REQUEST_URL + url)
    .then(res => {
      context.commit('setCollectionStats', res.data);
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async attributes(context, params) {
    var url = 'collection/attributes/' + params.chain_id + '/' + params.id;
    
    return axios.get(REQUEST_URL + url)
    .then(res => {
      context.commit('setCollectionAttributes', res.data);
      return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async viewFeatured(context, params) {
    let category = params.category;
    var url = 'collection/featured?category=' + category;
    return axios.get(REQUEST_URL + url)
    .then(res => {
      //console.log(res.data, 'featured');
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async nfts(context, params) {
    let url = 'search/tokens_list/?' + 
    'pageIndex=' + params.page + 
    '&pageWidth=' + params.size +
    '&chain_id=' + params.chain_id +
    '&collection_address=' + params.id +
    '&visible=' + true;
    
    if(params.min_price!=null && params.min_price!=''){
      url = url + '&minPrice=' + params.min_price;
    }

    if(params.max_price!=null && params.max_price!=''){
      url = url + '&maxPrice=' + params.max_price;
    }

    if(params.atts!=null){
      for(let i=0; i<params.atts.length; i++){
        for(let att in  params.atts[i]){
          if(att!='Status' && att!='Price'){
            url = url + '&' + encodeURIComponent(att) + '=' + encodeURIComponent(params.atts[i][att]);
          }else if(att=='Status'){
            url = url + '&status=ACTIVE';
          }
        }
      }
    }
   //console.log(url);
    return axios.get(REQUEST_URL + url)
    .then(res => {
      //console.log(res.data.tokens);
      //context.commit('setNfts', res.data);
      
      if(params.page == 1 || params.featured==true){
        context.commit('setNfts', res.data);
      }else{
        context.commit('addToNfts', res.data);
      } 
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async userNfts(context, params) {
    let url = 'search/user_tokens/?' + 
    'pageIndex=' + params.page + 
    '&pageWidth=' + params.size +
    '&wallet_address=' + params.wallet_address +
    '&sortBy=0';
    
    if(params.min_price!=''){
      url = url + '&minPrice=' + params.min_price;
    }

    if(params.max_price!=''){
      url = url + '&maxPrice=' + params.max_price;
    }

    if(params.atts!=null){
      for(let i=0; i<params.atts.length; i++){
        for(let att in  params.atts[i]){
          if(att!='Status' && att!='Price'){
            url = url + '&' + encodeURIComponent(att) + '=' + encodeURIComponent(params.atts[i][att]);
          }else if(att=='Status'){
            url = url + '&status=ACTIVE';
          }
          //url = url + '&' + encodeURIComponent(att) + '=' + encodeURIComponent(params.atts[i][att]);
        }
      }
    }
    //console.log(params, 'params');
    //console.log(url, 'url');
    return axios.get(REQUEST_URL + url)
    .then(res => {
     //console.log(res.data.tokens);
      //context.commit('setNfts', res.data);
      
      if(params.page == 1){
        context.commit('setNfts', res.data);
      }else{
        context.commit('addToNfts', res.data);
      } 
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async nftSingle(context, params) {
    var url = 'collection/view_token/' + params.chain_id + '/' + params.collection_id + '/' + params.token_id;
    //console.log(REQUEST_URL + url);
    return axios.get(REQUEST_URL + url)
    .then(res => {
      //console.log(res.data[0]);
      //context.commit('setNft', res.data[0]);
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async tokenHistory(context, params) {
    var url = 'collection/price_history/' + params.chain_id + '/' + params.collection_id + '/' + params.token_id;
    //console.log(REQUEST_URL + url);
    return axios.get(REQUEST_URL + url)
    .then(res => {
      //console.log(res.data[0]);
      //context.commit('setNft', res.data[0]);
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async activity(context, params) {
    var url = 'collection/item_activity/' + params.chain_id + '/' + params.collection_id + '/' + params.token_id;
    //console.log(REQUEST_URL + url);
    return axios.get(REQUEST_URL + url)
    .then(res => {
      //console.log(res.data);
      //context.commit('setNft', res.data[0]);
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async search(context, params) {
    var url = 'search?query=' + params.search;
    //console.log(REQUEST_URL + url);
    return axios.get(REQUEST_URL + url)
    .then(res => {
      //console.log(res.data);
      context.commit('setSearchResults', res.data);
      //return true;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
}

// mutations
const mutations = {
  setRequest(state, response) {
    //console.log(response);
    state.responseData = response;
  },
  setCollection(state, response) {
   //console.log(response);
    state.collectionData = response;
  },
  setCollectionStats(state, response) {
   //console.log(response);
    state.collectionStatsData = response;
  },
  setCollectionAttributes(state, response) {
   //console.log(response);
    state.collectionAttsData = response;
  },
  setSearchResults(state, response) {
    //console.log(response,'response');
    state.searchData = response;
  },
  setNfts(state, response) {
    state.nftData = response;
  },
  addToNfts(state, response) {
   //console.log('adding to');
   //console.log(response);
    var j = state.nftData['tokens'].length;
    const max = response['tokens'].length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.nftData['tokens'][j] = response['tokens'][i];
      j = j+1;
    }
  },
  addToRequest(state, response) {
    var j = state.responseData['images'].length;
    const max = response['images'].length;
    const min = 0;
    for (let i = min; i < max; i++) {
      state.responseData['images'][j] = response['images'][i];
      j = j+1;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
