import Web3 from "web3";
//import Web3Modal from "web3modal";
//import WalletConnectProvider from "@walletconnect/web3-provider";
import axios from 'axios';

// initial state
const BigNumber = require('bignumber.js')
const REQUEST_URL = process.env.VUE_APP_REQUEST_URL;
const WYVERN_PROXY_REGISTRY =  process.env.VUE_APP_WYVERN_PROXY_REGISTRY;
const WYVERN_IMPLEMENTATION =  process.env.VUE_APP_WYVERN_IMPLEMENTATION;
const FEE_RECIPIENT = process.env.VUE_APP_WYVERN_FEE_RECIPIENT;
//const FEE_RECIPIENT = "0x0000000000000000000000000000000000000000";
const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";
const royaltyRecieverAddress = "0x0000000000000000000000000000000000000000";
const creatorRoyalty = 0;

const wyvernProxyRegistry = require('../../includes/WyvernProxyRegistry');
const WyvernExchange = require('../../includes/WyvernProtocol');
const erc721_abi = require('../../includes/ERC721LuxTokenLite');

const makeOrder = (exchange, isMaker, accounts) => ({
  exchange: exchange,
  maker: accounts[0],
  taker: NULL_ADDRESS,
  feeRecipient: FEE_RECIPIENT,
  side: 0,
  saleKind: 0,
  target: NULL_ADDRESS,
  tokenId: new BigNumber(0),
  howToCall: 0, // regular call not a delegate call
  calldata: '0x',
  replacementPattern: '0x',
  staticTarget: NULL_ADDRESS,
  staticExtradata: '0x',
  paymentToken: NULL_ADDRESS,
  basePrice: new BigNumber(0),
  extra: new BigNumber(0),
  listingTime: new BigNumber(0),
  expirationTime: new BigNumber(0),
  eip2981Support: 0, // Default not supports
  creatorRoyalty: new BigNumber(creatorRoyalty),
  royaltyRecieverAddress: royaltyRecieverAddress,
  clientPlatformFee: new BigNumber(0),
  salt: new BigNumber(0),
})

const state = () => ({
  proxyData:null,
  collectionData:null,
  nftData:[],
  nftSingle:null,
  orderState:''
})  

// getters
const getters = {}

// actions
const actions = {
  async viewProxy(context, params) {
    var url = 'user/proxy/' + params.chain_id + '/' + params.wallet_id;
    return axios.get(REQUEST_URL + url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-access-token':  context.rootState.auth.token
      }
    })
    .then(res => {
      if(res.data!=NULL_ADDRESS){
        context.commit('setProxy', res.data);
        return res.data
      }else{
        return ('not found');
      }
    })
    .catch(error => {
      return({'error': error});
      //return({'error': error + ' ' + REQUEST_URL});
    });
  },
  // Creates the users proxy contract address.  This is a special contract that must be used when selling 
  // with the wyvern protocol.  Note that we are using a lot of js sample code that will not work simply to 
  // pseudo-code the process.  Also note that this creates an on-chain event that will cost them gas to create.
  // They are literally going to deploy a smart contract via the wyvern protocol.
  async createProxy(context) {
      // Get a Web3 instance for the wallet
      let web3Modal = context.rootState.wallet.web3Modal;
      const provider = await web3Modal.connect();

      // Get a Web3 instance for the wallet
      const web3 = new Web3(provider);

      //const web3 = new Web3();
      var accounts = await web3.eth.getAccounts();
      
      const proxyRegistry = new web3.eth.Contract(wyvernProxyRegistry, WYVERN_PROXY_REGISTRY);
      let wallet = accounts[0];
      
      const testProxy = await proxyRegistry.methods.proxies(wallet).call();
      let userProxy;
      //console.log(testProxy, 'proxy testing');
      if(testProxy==NULL_ADDRESS){
        try {
          userProxy = await proxyRegistry.methods.registerProxy().send({from:wallet});
          //const userProxy = await proxyRegistry.methods.registerProxy().call();
          if(userProxy!=undefined){
            return userProxy.address;
          }
        } catch (error) {
         //console.log(error);
          return {'error' : 'Unable to create new user proxy.'};
        }
      }else{
        userProxy = testProxy;
        return userProxy;
      }      
  },
  async grantApproval(context, params){
    let web3Modal = context.rootState.wallet.web3Modal;
    const provider = await web3Modal.connect();

    // Get a Web3 instance for the wallet
    const web3 = new Web3(provider);
    var accounts = await web3.eth.getAccounts();
    let wallet = accounts[0];

    const erc721Instance = new web3.eth.Contract(erc721_abi, params.collection_address);
    const testApproval = await erc721Instance.methods.isApprovedForAll(wallet, params.userProxy).call();
    let erc721Approval;
    //console.log(testProxy, 'proxy testing');
    if (!testApproval) {
      try {
        erc721Approval = await erc721Instance.methods.setApprovalForAll(params.userProxy, true).send({ from: wallet });
        //const userProxy = await proxyRegistry.methods.registerProxy().call();
        if (erc721Approval != undefined) {
         //console.log(erc721Approval);
          return true;
        }
      } catch (error) {
       //console.log(error);
        return { 'error': 'Unable to create approval.' };
      }
    } else {
      return true;
    }
  },
  async listItem(context, params) {
    let web3Modal = context.rootState.wallet.web3Modal;
    const provider = await web3Modal.connect();

    // Get a Web3 instance for the wallet
    const web3 = new Web3(provider);

    //const web3 = new Web3();
    var accounts = await web3.eth.getAccounts();
    const ownerAccount = accounts[0];
    //await listItem(web3, config.TestERC721, tokenId, ownerAccount, process.env.ACC_3, ownerPK); // PlatformFee Reciepient is ACC_3
    
    let expirationTime = new Date();
    expirationTime.setDate(expirationTime.getDate() + (params.duration * 30));
   //console.log(expirationTime.getTime(), 'expirationTime');

    // Construct sellOrder
    const sellOrder = makeOrder(WYVERN_IMPLEMENTATION, false, accounts);
    sellOrder.feeRecipient = FEE_RECIPIENT;
    sellOrder.side = 1;
    sellOrder.target = params.collection_id;
    sellOrder.tokenId = params.token_id;
    // Calculate the call data for transfer 1 nft
    const callData_ = web3.eth.abi.encodeFunctionCall(
      {
        name: 'transferFrom',
        type: 'function',
        inputs: [
          {
            type: 'address',
            name: 'from',
          },
          {
            type: 'address',
            name: 'to',
          },
          {
            type: 'uint256',
            name: 'tokenId',
          },
        ],
      },
      [ownerAccount, ownerAccount, params.token_id],
    );
    sellOrder.calldata = callData_;
    sellOrder.basePrice = web3.utils.toWei(params.price, 'ether');
    sellOrder.extra = web3.utils.toWei('0', 'ether');
    
    //sellOrder.listingTime = Date.now().toString();
    //sellOrder.expirationTime = expirationTime.getTime().toString();
    sellOrder.listingTime = '0';
    sellOrder.expirationTime = '0';

    sellOrder.eip2981Support = 0; // Does not support Supports EIP2981 interface
    sellOrder.creatorRoyalty = new BigNumber(creatorRoyalty); // 5% of 0.001 = 0.00005 eth creator Royalty fetched from DB.
    sellOrder.royaltyRecieverAddress = royaltyRecieverAddress; // null address
    sellOrder.clientPlatformFee = web3.utils.toWei('0', 'ether');
    sellOrder.salt = Date.now();
    // Fetch Current Nonce from contract address
    const WyvernExchangeInstance = new web3.eth.Contract(
      WyvernExchange,
      WYVERN_IMPLEMENTATION,
    );
    const currentNonce = await WyvernExchangeInstance.methods.nonces(ownerAccount).call(); // Fetch current nonce for the owner
    //console.log(currentNonce, 'nonce');
    // replacement pattern
    // TODO: The replacement pattern should only contain the bitmappings for the buyerAccount. At the moment, it replaces all of it.
    sellOrder.replacementPattern = callData_.split('').reduce((acc, item, i) => {
      if (i === 0 || i === 1) {
        return acc + item
      }
      return acc + 'f'
    }, '')

   //console.log(sellOrder, 'sellOrder');

    const msgParams = {
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        Order: [
          { name: 'exchange', type: 'address' },
          { name: 'maker', type: 'address' },
          { name: 'taker', type: 'address' },
          { name: 'feeRecipient', type: 'address' },
          { name: 'side', type: 'uint8' },
          { name: 'saleKind', type: 'uint8' },
          { name: 'target', type: 'address' },
          { name: 'tokenId', type: 'uint256' },
          { name: 'howToCall', type: 'uint8' },
          { name: 'calldata', type: 'bytes' },
          { name: 'replacementPattern', type: 'bytes' },
          { name: 'staticTarget', type: 'address' },
          { name: 'staticExtradata', type: 'bytes' },
          { name: 'paymentToken', type: 'address' },
          { name: 'basePrice', type: 'uint256' },
          { name: 'extra', type: 'uint256' },
          { name: 'listingTime', type: 'uint256' },
          { name: 'expirationTime', type: 'uint256' },
          { name: 'eip2981Support', type: 'uint8' },
          { name: 'creatorRoyalty', type: 'uint256' },
          { name: 'royaltyRecieverAddress', type: 'address' },
          { name: 'clientPlatformFee', type: 'uint256' },
          { name: 'salt', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
        ],
      },
      primaryType: 'Order',
      domain: {
        name: 'Wyvern Exchange Contract by ARTM',
        version: '2.3',
        chainId: params.chain_id,
        verifyingContract: WYVERN_IMPLEMENTATION,
      },
      message: {
        ...sellOrder,
        nonce: currentNonce,
      },
    }
    //const sendParams = [msgParams, ownerAccount];
    //console.log(sendParams, 'params');
    
    //let sellOrderSignature;
    /* let sellOrderSignature = await web3.eth.currentProvider.send(
      {
        method: 'eth_signTypedData_v4',
        params: [ownerAccount, JSON.stringify(msgParams)],
        from: ownerAccount,
      },
      function (err, result) {
        if (err) {
         //console.log(err)
          //return resolve(null)
        }
        if (result.error) {
         //console.log(result.error.message)
          //return resolve(null)
        }
       //return resolve(result.result)
      },
    )*/
    //let sellOrderSignature = await this.dispatch("wallet/accountSignature");
    let sellOrderSignature = await this.dispatch("order/signMsg", {
      web3: web3, 
      ownerAccount: ownerAccount, 
      msgParams: msgParams
    });
   //console.log('Signature for SellOrder_________________')
   //console.log(sellOrderSignature);

    const formParams = new URLSearchParams();
    formParams.append('chain_id', params.chain_id);
    formParams.append('wallet_address', ownerAccount);
    formParams.append('collection_address', params.collection_id);
    formParams.append('token_id', params.token_id);
    formParams.append('order', JSON.stringify(sellOrder));
    formParams.append('client_signature', sellOrderSignature);
    formParams.append('nonce', currentNonce);
    //console.log(JSON.stringify(sellOrder), 'form params');

    return axios.post(REQUEST_URL + 'order/listings', formParams,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-access-token':  context.rootState.auth.token,
      }
    })
    .then(res => {
      //console.log(res.data.tokens);
      //context.commit('setNfts', res.data);
      return res.data;

    })
    .catch(error => {
     //console.log(error);
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async signMsg(context, params) {
    //console.log(params.ownerAccount,'owner');
    return new Promise((resolve) => {
      params.web3.eth.currentProvider.sendAsync(
        {
          method: 'eth_signTypedData_v4',
          params: [params.ownerAccount, JSON.stringify(params.msgParams)],
          from: params.ownerAccount,
        },
        function (err, result) {
          if (err) {
           //console.log(err)
            return resolve(null)
          }
          if (result.error) {
           //console.log(result.error.message)
            return resolve(null)
          }
          return resolve(result.result)
        },
      )
    })
  },
  async status(context, params) {
    var url = 'order/listings/' + params.chain_id + '/' + params.collection_id + '/' + params.token_id;
   //console.log(REQUEST_URL + url);
    return axios.get(REQUEST_URL + url)
    .then(res => {
      //console.log(res.data[0]);
      //context.commit('setNft', res.data[0]);
      return res.data;
    })
    .catch(error => {
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async buyItem(context, params) {
    let web3Modal = context.rootState.wallet.web3Modal;
    const provider = await web3Modal.connect();

    // Get a Web3 instance for the wallet
    const web3 = new Web3(provider);

    //const web3 = new Web3();
    var accounts = await web3.eth.getAccounts();
    const buyerAccount = accounts[0];
    const erc721Instance = new web3.eth.Contract(erc721_abi, params.collection_id);
    const from_wallet = await erc721Instance.methods.ownerOf(parseInt(params.token_id)).call();

    const buyOrder = makeOrder(WYVERN_IMPLEMENTATION, true, accounts);

    // Construct BuyOrder
    buyOrder.feeRecipient = NULL_ADDRESS;
    buyOrder.target = params.collection_id;
    buyOrder.tokenId = parseInt(params.token_id);
    // Calculate the call data for transfer 1 nft
    const callData_ = web3.eth.abi.encodeFunctionCall(
      {
        name: 'transferFrom',
        type: 'function',
        inputs: [
          {
              type: 'address',
              name: 'from',
          },
          {
              type: 'address',
              name: 'to',
          },
          {
              type: 'uint256',
              name: 'tokenId',
          },
          ],
        },
        [params.sellOrderDoc.maker, buyerAccount, params.token_id],
      )
    buyOrder.calldata = callData_;
    buyOrder.basePrice = params.sellOrderDoc.basePrice;
    buyOrder.extra = params.sellOrderDoc.extra;
    buyOrder.listingTime = '0';
    buyOrder.expirationTime = '0';
    buyOrder.eip2981Support = 0; // Does not Supports interface.
    buyOrder.creatorRoyalty = params.sellOrderDoc.creatorRoyalty;
    buyOrder.royaltyRecieverAddress = params.sellOrderDoc.royaltyRecieverAddress; //
    buyOrder.clientPlatformFee = params.sellOrderDoc.clientPlatformFee;
    //buyOrder.maker = params.sellOrderDoc.maker;
    buyOrder.maker = buyerAccount;
    buyOrder.salt = params.sellOrderDoc.salt;
   //console.log(buyOrder);

    const WyvernExchangeInstance = new web3.eth.Contract(
      WyvernExchange,
      WYVERN_IMPLEMENTATION,
    );
    // Fetch current Nonce value
    const currentNonce = await WyvernExchangeInstance.methods.nonces(buyerAccount).call();
   //console.log(currentNonce);
    // SignTyped Data
    const msgParams = {
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        Order: [
          { name: "exchange", type: "address" },
          { name: "maker", type: "address" },
          { name: "taker", type: "address" },
          { name: "feeRecipient", type: "address" },
          { name: "side", type: "uint8" },
          { name: "saleKind", type: "uint8" },
          { name: "target", type: "address" },
          { name: "tokenId", type: "uint256" },
          { name: "howToCall", type: "uint8" },
          { name: "calldata", type: "bytes" },
          { name: "replacementPattern", type: "bytes" },
          { name: "staticTarget", type: "address" },
          { name: "staticExtradata", type: "bytes" },
          { name: "paymentToken", type: "address" },
          { name: "basePrice", type: "uint256" },
          { name: "extra", type: "uint256" },
          { name: "listingTime", type: "uint256" },
          { name: "expirationTime", type: "uint256" },
          { name: "eip2981Support", type: "uint8" },
          { name: "creatorRoyalty", type: "uint256" },
          { name: "royaltyRecieverAddress", type: "address" },
          { name: "clientPlatformFee", type: "uint256" },
          { name: "salt", type: "uint256" },
          { name: "nonce", type: "uint256" },
        ],
      },
      primaryType: 'Order',
      domain: {
        name: 'Wyvern Exchange Contract by ARTM',
        version: '2.3',
        chainId: params.chain_id,
        verifyingContract: WYVERN_IMPLEMENTATION,
      },
      message: {
        ...buyOrder,
        nonce: currentNonce,
      },
    }

    let buyOrderSignature = await this.dispatch("order/signMsg", {
      web3: web3, 
      ownerAccount: buyerAccount, 
      msgParams: msgParams
    });

   /* const buyOrderSignature = await signMsg(
      web3,
      msgParams,
      buyerAccount.toLowerCase(),
    ) */

   //console.log('Signature for BuyOrder_________________')
   //console.log(buyOrderSignature);

    const subBuySignature = buyOrderSignature.substr(2)
    const br = '0x' + subBuySignature.slice(0, 64)
    const bs = '0x' + subBuySignature.slice(64, 128)
    const bv = parseInt('0x' + subBuySignature.slice(128, 130), 16)
    // check Orders can match
   //console.log('Checking matchability___________')
    const sellOrder = { ...params.sellOrderDoc }

    // check the sellOrder Signature
    let checkSellSignature = await this.dispatch("order/checkSellSignature", {
      order: sellOrder, 
      nonce: sellOrder.nonce, 
      client_signature: sellOrder.client_signature
    });

   //console.log(checkSellSignature);
    if(checkSellSignature==false){
      return ({'error': 'unverifed signature'});
    }
   //console.log('_______________________________________')
   //console.log(sellOrder)
   //console.log(buyOrder)
    //sellOrder.listingTime = 0;
    //sellOrder.expirationTime = 0;
    
    const canMatch = await WyvernExchangeInstance.methods
      .ordersCanMatch_(
        [
          buyOrder.exchange,
          buyOrder.maker,
          buyOrder.taker,
          buyOrder.feeRecipient,
          buyOrder.target,
          buyOrder.staticTarget,
          buyOrder.paymentToken,
          buyOrder.royaltyRecieverAddress,
          sellOrder.exchange,
          sellOrder.maker,
          sellOrder.taker,
          sellOrder.feeRecipient,
          sellOrder.target,
          sellOrder.staticTarget,
          sellOrder.paymentToken,
          sellOrder.royaltyRecieverAddress
        ],
        [
          buyOrder.tokenId,
          buyOrder.basePrice,
          buyOrder.extra,
          buyOrder.listingTime,
          buyOrder.expirationTime,
          buyOrder.creatorRoyalty,
          buyOrder.clientPlatformFee,
          buyOrder.salt,
          sellOrder.tokenId,
          sellOrder.basePrice,
          sellOrder.extra,
          sellOrder.listingTime,
          sellOrder.expirationTime,
          sellOrder.creatorRoyalty,
          sellOrder.clientPlatformFee,
          sellOrder.salt,
        ],
        [
          buyOrder.side,
          buyOrder.saleKind,
          buyOrder.howToCall,
          buyOrder.eip2981Support,
          sellOrder.side,
          sellOrder.saleKind,
          sellOrder.howToCall,
          sellOrder.eip2981Support,
        ],
        buyOrder.calldata,
        sellOrder.calldata,
        buyOrder.replacementPattern,
        sellOrder.replacementPattern,
        buyOrder.staticExtradata,
        sellOrder.staticExtradata,
      )
      .call()
   //console.log('Order can match?', canMatch)
    if(!canMatch){
      return ('orders do not match'); 
    }
    
      // send Real Transaction
    try {
    return await WyvernExchangeInstance.methods
        .atomicMatch_(
          [
            buyOrder.exchange,
            buyOrder.maker,
            buyOrder.taker,
            buyOrder.feeRecipient,
            buyOrder.target,
            buyOrder.staticTarget,
            buyOrder.paymentToken,
            buyOrder.royaltyRecieverAddress,
            sellOrder.exchange,
            sellOrder.maker,
            sellOrder.taker,
            sellOrder.feeRecipient,
            sellOrder.target,
            sellOrder.staticTarget,
            sellOrder.paymentToken,
            sellOrder.royaltyRecieverAddress
          ],
          [
            buyOrder.tokenId,
            buyOrder.basePrice,
            buyOrder.extra,
            buyOrder.listingTime,
            buyOrder.expirationTime,
            buyOrder.creatorRoyalty,
            buyOrder.clientPlatformFee,
            buyOrder.salt,
            sellOrder.tokenId,
            sellOrder.basePrice,
            sellOrder.extra,
            sellOrder.listingTime,
            sellOrder.expirationTime,
            sellOrder.creatorRoyalty,
            sellOrder.clientPlatformFee,
            sellOrder.salt,
          ],
          [
            buyOrder.side,
            buyOrder.saleKind,
            buyOrder.howToCall,
            buyOrder.eip2981Support,
            sellOrder.side,
            sellOrder.saleKind,
            sellOrder.howToCall,
            sellOrder.eip2981Support,
          ],
          buyOrder.calldata,
          sellOrder.calldata,
          buyOrder.replacementPattern,
          sellOrder.replacementPattern,
          buyOrder.staticExtradata,
          sellOrder.staticExtradata,
          [bv, sellOrder.client_signature.v],
          [
            br,
            bs,
            sellOrder.client_signature.r,
            sellOrder.client_signature.s,
            '0x0000000000000000000000000000000000000000000000000000000000000000',
          ],
        )
        .send({
          from: accounts[0],
          value: sellOrder.basePrice,
        })
        .on('receipt', async (tx) => {
          //console.log(tx, 'finished sale');
          //chain_id, collection_address, token_id, maker, taker, price, tx_hash
          //return ({'tx_hash':tx})
          const to_wallet = await erc721Instance.methods.ownerOf(parseInt(sellOrder.tokenId)).call();
          
          let finalResponse = await this.dispatch("order/finalize", {
            chain_id: sellOrder.chain_id,
            target: sellOrder.target,
            from_wallet: from_wallet,
            to_wallet: to_wallet,
            token_id: sellOrder.tokenId,
            price:  sellOrder.basePrice,
            tx_hash: tx
          });

          if(finalResponse.success=='Finalized Order.'){
           //console.log("db updated");
            context.commit('setOrderState', 'complete');
          }
          
        })
        .on('error', (er) => {
         //console.log(er)
          return ({'error':er})
        })
      } catch (error) {
       //console.log(error);
        return { 'error': 'Unable to send order.' };
      }
    
  },
  async finalize(context,params){
    //const { chain_id, collection_address, token_id, maker, taker, price, tx_hash } = req.body; 
    const formParams = new URLSearchParams();
    formParams.append('chain_id', params.chain_id);
    formParams.append('collection_address', params.target);
    formParams.append('token_id', params.token_id);
    formParams.append('from_wallet', params.from_wallet.toLowerCase());
    formParams.append('to_wallet', params.to_wallet.toLowerCase());
    formParams.append('price', params.price);
    formParams.append('tx_hash', JSON.stringify(params.tx_hash));
    //console.log(JSON.stringify(sellOrder), 'form params');
    //console.log(params, 'params');
    
    return axios.post(REQUEST_URL + 'order/finalize', formParams,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-access-token':  context.rootState.auth.token,
      }
    })
    .then(res => {
      //console.log(res.data.tokens);
      //context.commit('setNfts', res.data);
      return res.data;

    })
    .catch(error => {
     //console.log(error);
      return({'error': error.message + ' ' + REQUEST_URL});
    });
  },
  async checkSellSignature(context,params){
    //const { chain_id, collection_address, token_id, maker, taker, price, tx_hash } = req.body; 
    const formParams = new URLSearchParams();
    formParams.append('order', JSON.stringify(params.order));
    formParams.append('nonce', params.nonce);
    formParams.append('client_signature', JSON.stringify(params.client_signature));
    
   //console.log(params, 'params');
    return axios.post(REQUEST_URL + 'order/listings/validatesell', formParams,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-access-token':  context.rootState.auth.token,
      }
    })
    .then(() => {
     //console.log(res.data);
      //context.commit('setNfts', res.data);
      return true;
    })
    .catch(error => {
      console.log(error);
      return false;
    });
  },
  async cancelSell(context, params){
    let web3Modal = context.rootState.wallet.web3Modal;
    const provider = await web3Modal.connect();

    // Get a Web3 instance for the wallet
    const web3 = new Web3(provider);
    const order = params.sellOrderDoc;
    
    var accounts = await web3.eth.getAccounts();
    const wallet = accounts[0];
    
    const WyvernExchangeInstance = new web3.eth.Contract(
      WyvernExchange,
      WYVERN_IMPLEMENTATION,
    );
    
    const orderHash = await WyvernExchangeInstance.methods.hashToSign_(
      [order.exchange, order.maker, order.taker, order.feeRecipient, order.target, order.staticTarget, order.paymentToken, order.royaltyRecieverAddress],
      [order.tokenId, order.basePrice, order.extra, order.listingTime, order.expirationTime, order.creatorRoyalty, order.clientPlatformFee, order.salt],
      order.side, 
      order.saleKind, 
      order.howToCall, 
      order.eip2981Support,
      order.calldata,
      order.replacementPattern,
      order.staticExtradata
    ).call();
    
   //console.log(orderHash);
    // order hash should be this: "0xa0c3c96938c5b060aef8c39084cf8a3b708ca799f630cec3c72e943728875271";
    const orderStatus = await WyvernExchangeInstance.methods.cancelledOrFinalized(orderHash).call();
   //console.log(orderStatus);
    
    if (orderStatus==false) {
     //console.log(order);
      const cancelOrder = await WyvernExchangeInstance.methods.cancelOrder_(
        [order.exchange, order.maker, order.taker, order.feeRecipient, order.target, order.staticTarget, order.paymentToken, order.royaltyRecieverAddress],
        [order.tokenId, order.basePrice, order.extra, order.listingTime, order.expirationTime, order.creatorRoyalty, order.clientPlatformFee, order.salt],
        order.side, 
        order.saleKind, 
        order.howToCall, 
        order.eip2981Support,
        order.calldata,
        order.replacementPattern,
        order.staticExtradata,
        order.client_signature.v,
        order.client_signature.r,
        order.client_signature.s
      ).send({from:wallet});
      console.log(cancelOrder, 'cancel order'); 
    }

    const formParams = new URLSearchParams();
    formParams.append('maker_address', order.maker);
    formParams.append('chain_id', order.chain_id);
    formParams.append('collection_address', order.target);
    formParams.append('token_id', order.tokenId);
    formParams.append('order_side', order.side);
    
   //console.log(params, 'params');
    return axios.post(REQUEST_URL + 'order/listings/cancel_single', formParams,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-access-token':  context.rootState.auth.token,
      }
    })
    .then(res => {
      console.log(res.data);
      //context.commit('setNfts', res.data);
      return true;
    })
    .catch(error => {
      console.log(error);
      return false;
    });
    

  }
}

// mutations
const mutations = {
  setProxy(state, response) {
    //console.log(response);
    state.proxyData = response;
  },
  setOrderState(state, response){
    state.orderState = response;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
