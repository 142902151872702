import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { IonicVue } from '@ionic/vue';
// import { createMetaManager } from 'vue-meta'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/styles.css';
import 'viewerjs/dist/viewer.css'
import store from './store'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret, faClock, faGlobe } from '@fortawesome/free-solid-svg-icons'
//import { faGlobe } from '@fortawesome/free-regular-svg-icons'
import { faEthereum, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faUserSecret, faEthereum, faClock, faInstagram, faTwitter, faGlobe)

import VueViewer from 'v-viewer'

const app = createApp(App).use(store)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(IonicVue)
  .use(VueViewer)
  .use(router);
//.use(createMetaManager());

router.isReady().then(() => {
  app.mount('#app');
});