<template>
  <div style="width: 100%; height: auto; background-image: linear-gradient(rgba(20,20,20,1.0), rgba(40,40,40,0.75), rgba(0,0,0,0))">
    <ion-grid style="max-width: 1200px;">
      <ion-row >
        <ion-col size="12" size-sm="5">
          <ion-card v-if="collection!=null && this.tokens.length>0" :href="'/nft/' + this.chain_id + '/' + this.collection.collection_address + '/' + showRandomNft('token_id')" class="card">
            <div class="place-card-image">
            <img class="nft-image" v-if="this.tokens.length>0" :src="showRandomNft('photo')" />
            </div>
            <div class="card-txt-background"></div>
            <h4>
              {{ showRandomNft('label') }}
            </h4> 
          </ion-card>
          <ion-card v-else class="card">
            <div class="place-card-image">
              <div class="load-wraper">
                <div class="activity"></div>
              </div>
            </div>
          </ion-card>
        </ion-col>
        <ion-col v-if="featured!=null" class="intro" size="12" size-sm="7">
          <div class="company">{{ showTitle($route.name) }}</div>
          <h1 v-if="featured.title_1!=''">{{ featured.title_1 }}</h1>
          <h3 v-if="featured.title_2!=''">
            {{ featured.title_2 }}
          </h3>
          <p v-if="featured.body_1!=''">{{ featured.body_1 }}</p>
          <p v-if="featured.body_2!=''">{{ featured.body_2 }}</p>
          <ion-button
            v-if="featured.button_1_label!=''"
            class="explore-button"
            :href="featured.button_1_url"
            >{{ featured.button_1_label }}</ion-button
          >
          <ion-button
            v-if="featured.button_2_label!=''"
            class="signup-button"
            :href="featured.button_2_url"
            >{{ featured.button_2_label }}</ion-button
          >
      
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <ion-grid style="max-width: 1200px">
    <ion-row v-if="collection!=null">
      <ion-col>
        <h2 v-if="collection.name != undefined">{{ collection.name }}
          </h2>
          <p v-if="collection.description != undefined">{{ collection.description }}
          </p>
      </ion-col>
    </ion-row>
    <ion-row v-if="tokens.length==0">
      <ion-col v-for="n in 4"
            :key="'featureKey-' +n" size="12" size-sm="3">
        <ion-card class="card">
          <div class="place-card-image">
            <div class="load-wraper">
              <div class="activity"></div>
            </div>
          </div>
        </ion-card>
      </ion-col>
    </ion-row> 
    <ion-row v-else-if="collection!=null">
      <ion-col v-for="nft in tokens"
            :key="'featureKey-' + nft._id" size="12" size-sm="3">
        <ion-card :href="'/nft/' + this.chain_id + '/' + this.collection.collection_address + '/' + nft.token_id" class="sub-card">
          <div class="place-card-image">
            <img class="nft-image" :src="showNftImage(nft)" />
          </div>
          <div class="card-txt-background"></div>
          <h4>
            {{
              nft.token_metadata.name
            }}
          </h4>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonCard, IonGrid, IonRow, IonCol, IonButton } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FeaturedContainer",
  props: {
    chain_id: {Number, default:1},
    collection: {Object, default:null},
    featured: {Object, default:null}
  },
  /* setup () {
    useMeta({ 
    description: '1of1 creates custom NFT markeplaces for luxury brands who are looking to have their collections set apart from the rest. We work personally with each brand to develop a world-class user experience.',
    og: {
        title: '1of1 NFT Marketplace',
        description: '1of1 creates custom NFT markeplaces for luxury brands who are looking to have their collections set apart from the rest. We work personally with each brand to develop a world-class user experience.',
        image: [
          'https://1of1.biz/assets/section2_L_2.png',
        ]
      }, })
  }, */
  components: {
    IonCard,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  },
  /* data() {
    return {
      rand: null,
      chain_id: 1,
      collection: null,
      featured: null,
      max_tokens: 8
    };
  },*/
  async mounted() {
    //this.getFeatured();
    //this.getCollection();
    //this.getCollectionNfts();
  },
  methods: {
    showImage(object) {
      let  photo = "/assets/bg_2.jpg";
      if(object.cdn_image!=undefined){
        photo = object.cdn_image;
      }
      return photo;
    },
    showProfileImage(cdn_image) {
      let  photo = "/assets/icon/icon.png";
      if(cdn_image!=undefined){
        photo = cdn_image;
      }
      return photo;
    },
    showRandomNft(type){
      if(this.tokens.length==0){
        return "";
      }
      let nft = this.tokens[this.random];
      if(type=='photo'){
      let photo = '/assets/bg_2.jpg';
        if(nft.cdn_card_image!=null){
          photo = nft.cdn_card_image;
        }else if(nft.cdn_image!=null){        
          photo = nft.cdn_image;
        }
        return photo;
      }else if(type=='label'){
        return nft.token_metadata.name;
      }else if(type=='token_id'){
        return nft.token_id;
      }
    },
    showNftImage(nft){
      let photo = '/assets/bg_2.jpg';
        if(nft.cdn_card_image!=null){
          photo = nft.cdn_card_image;
        }else if(nft.cdn_image!=null){        
          photo = nft.cdn_image;
        }
        return photo;
    },
    showTitle(name){
      let title = "";
      if(name=="home"){
        title = "1of1 NFT Marketplace";
      }else{
        title = name.charAt(0).toUpperCase() + name.slice(1);
      }
      return title;
    }
  },
  computed: {
    tokens() {
      if (this.$store.state.collection.nftData.tokens != undefined) {
        //console.log(this.$store.state.collection.nftData.tokens, 'found tokens');
        return this.$store.state.collection.nftData.tokens;
      } else {
       //console.log('none found');
        return [];
      }
    },
    random(){
      if (this.$store.state.collection.nftData.tokens != undefined) {
        //console.log(Math.random() * (this.$store.state.collection.nftData.tokens.length-1), 'pick random');
        //console.log(this.$store.state.collection.nftData.tokens.length, 'no tokens');
        return Math.floor(Math.random() * (this.$store.state.collection.nftData.tokens.length-1)); 
      }else{
        return 0;
      }
    }
  },
});
</script>

<style>
.my-custom-menu {
  --width: 500px;
}
</style>