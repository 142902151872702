import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { chains } from 'eth-chains'
const erc20_abi = require('../../includes/ERC20Token.json')
//import { mapState } from 'vuex';

const state = () => ({
    web3Modal: localStorage.getItem('web3Modal') || null,
    chainId: localStorage.getItem('chainId') || '',
    chainData: localStorage.getItem('chainData') || '',
    accounts: localStorage.getItem('accounts') || [],
    selectedAccount: localStorage.getItem('selectedAccount') || '',
    shortAddress: localStorage.getItem('shortAddress') || '',
    ethBalance: localStorage.getItem('ethBalance') || '',
    artmBalance: localStorage.getItem('artmBalance') || '',
    wethBalance: localStorage.getItem('wethBalance') || '',
})

// getters
const getters = {}

// actions
const actions = {
    async connectWallet(context) {
        let web3Modal = context.state.web3Modal;
        const provider = await web3Modal.connect();

        // Get a Web3 instance for the wallet
        const web3 = new Web3(provider);
        
        provider.on("connect", async (info) => {
            let chainId = parseInt(info.chainId)
            context.commit('setChainId', chainId);
        });

        provider.on("accountsChanged", async (accounts) => {
            if (accounts.length > 0) {
                context.commit('setAccounts', accounts);
                
                var selectedAccount = accounts[0].toLowerCase();
                context.commit('setSelectedAccount', selectedAccount);
                var start = selectedAccount.substring(0, 4) + "...";
                var stop = selectedAccount.substring(selectedAccount.length - 4, selectedAccount.length);
                var shortAddress = start.concat(stop);
                context.commit('setShortAddress', shortAddress);
                context.dispatch('getBalances', web3);
            } else {
                await context.dispatch('walletDisconnect')
            } 
            //console.log(accounts, "accountsChanged")
        });
        provider.on("chainChanged", async (chainId) => {
            chainId = parseInt(chainId)
            //console.log("chainChanged", chainId)
            context.commit('setChainId', chainId);
            const chainData = chains.getById(chainId);
            context.commit('setChainData', chainData.name);
            context.dispatch('getBalances', web3);
        });

        // Subscribe to provider disconnection
        provider.on("disconnect", (code, message) => {
           console.log(code);
           console.log(message);
        });
        
        // Get connected chain id from Ethereum node
        var chainId = await web3.eth.getChainId();
        context.commit('setChainId', chainId);

        // Load chain information over an HTTP API
        const chainData = chains.getById(chainId);
        context.commit('setChainData', chainData.name);
        //document.querySelector("#network-name").textContent = chainData.name;

        // Get list of accounts of the connected wallet
        // NOTE: metamask will only return the selected account
        var accounts = await web3.eth.getAccounts();
        context.commit('setAccounts', accounts);
        var selectedAccount = accounts[0].toLowerCase();
        context.commit('setSelectedAccount', selectedAccount);

        var start = selectedAccount.substring(0, 4) + "...";
        //console.log(selectedAccount.length);
        var stop = selectedAccount.substring(selectedAccount.length - 4, selectedAccount.length);
        //console.log(stop);
        var shortAddress = start.concat(stop);
        context.commit('setShortAddress', shortAddress);
        
        context.dispatch('getBalances', web3);
        
    },
    async getBalances(context, web3){
        const selectedAccount = context.state.selectedAccount;
        const balance = await web3.eth.getBalance(selectedAccount);
        const ethBalance = web3.utils.fromWei(balance, "ether");
        const humanFriendlyBalance = parseFloat(ethBalance).toFixed(4);
        context.commit('setEthBalance', humanFriendlyBalance); 

        //console.log(erc20_abi);
        const artmContract = '0x19ebAA7F212b09de2aee2a32D40338553C70e2e3';
        const tartmContract = '0x36EF9c96f7431cA311EB43996dc63d840a6a875e'; // Rinkeby   
        //const invWallet = '0xf04312ce5b9b8f91dadcbf6c20747d70797b57fb';
        let artmContractAddress = artmContract;
        if(context.state.chainId==4) artmContractAddress = tartmContract;
        // ARTM Smart Contract
        try{
            let tokenContract = new web3.eth.Contract(erc20_abi,artmContractAddress);
            /* let tokenSymbol = '';
            tokenContract.methods.symbol().call({from: selectedAccount}, function(error, result){
                if(error){
                   //console.log(error, 'error');
                }else if (result){
                    tokenSymbol = result;
                }
            }); */
            
            tokenContract.methods.balanceOf(selectedAccount).call({from: selectedAccount}, function(error, result){
                if(error){
                   //console.log(error.message, 'error');
                    context.commit('setArtmBalance', 0);
                }else if (result){
                    //console.log(result, 'artmResult')
                    let artmValue = result;
                    const artmBalance = web3.utils.fromWei(artmValue, "ether");
                    const artmFriendlyBalance = parseFloat(artmBalance).toFixed(4);
                    context.commit('setArtmBalance', artmFriendlyBalance);
                }
            });

            //let artmValue = await tokenContract.methods.balanceOf(invWallet).call();
           ////console.log(tokenSymbol);
            //console.log(artmFriendlyBalance);
        }catch(error){
            context.commit('setArtmBalance', 0);
           //console.log('token does not exist on this network: ' + error.message);
        }

        const wethContract = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
        
        //if(context.state.chainId==1){
            // Wrapped ETH Smart Contract
            try{
                let wEthtokenContract = new web3.eth.Contract(erc20_abi,wethContract);
                /* let wEthtokenSymbol = '';
                wEthtokenContract.methods.symbol().call({from: selectedAccount}, function(error, result){
                    if(error){
                       //console.log(error, 'error');
                    }else if (result){
                        wEthtokenSymbol = result;
                    }
                }); */
                //let wEthtokenSymbol = await wEthtokenContract.methods.symbol().call();

                wEthtokenContract.methods.balanceOf(selectedAccount).call({from: selectedAccount}, function(error, result){
                    if(error){
                       //console.log(error.message, 'error');
                        context.commit('setWethBalance', 0);
                    }else if (result){
                        //console.log(result, 'wethResult')
                        let wEthValue = result;
                        const wEthBalance = web3.utils.fromWei(wEthValue, "ether");
                        const wEthFriendlyBalance = parseFloat(wEthBalance).toFixed(4);
                        context.commit('setWethBalance', wEthFriendlyBalance);
                    }
                });

                //let wEthValue = await wEthtokenContract.methods.balanceOf(selectedAccount).call();
                //console.log(wEthtokenSymbol);
                //console.log(wEthFriendlyBalance);
            }catch(error){
                context.commit('setWethBalance', 0);
               //console.log('token does not exist on this network: ' + error.message);
            }
        /* }else{
            context.commit('setWethBalance', 0);
        } */
    },
    async accountSignature(context){
        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider, // required
                options: {
                    infuraId: "e0a3681de3704807b7decf022971810f", // required
                },
            },
        };

        var web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: true, // optional
            providerOptions, // required
        });
        var provider = await web3Modal.connect();
        
        // Get a Web3 instance for the wallet
        const web3 = new Web3(provider);
        //console.log("Web3 instance is", web3);

        const selectedAccount = context.state.selectedAccount;
        //console.log(selectedAccount, "selected accounts");
        var sig_message = `Welcome to 1of1!
        
Click to sign in and accept the 1of1 Terms of Service: https://1of1.biz/tos
        
This request will not trigger a blockchain transaction or cost any gas fees.
        
Your authentication status will reset after 24 hours.
        
Wallet address:
` + selectedAccount + `
        `;
       //console.log(sig_message);
        return await web3.eth.personal.sign(sig_message, selectedAccount, function (err, signature) {
            //console.log('signature returned');  // But maybe do some error checking. :-)
            return signature;
        });
    },
    async refreshAccountData(context) {        
        // Fetch account data for UI when
        // - User switches accounts in wallet
        // - User switches networks in wallet
        // - User connects wallet initially

        // Disable button while UI is loading.
        // fetchAccountData() will take a while as it communicates
        // with Ethereum node via JSON-RPC and loads chain data
        // over an API call.
        context.dispatch('connectWallet')
    },
    async walletDisconnect(context) {
        // Disconnect wallet button pressed.
        // console.log("Killing the wallet connection", context.state.provider);
        // console.log(provider.providers.length, 'providers length');
        // TODO: Which providers have close method?
        // is this even necessary?
        //if (context.state.provider.disconnect) {
        //    console.log("found a provider to close");
        //    await context.state.provider.disconnect();
        //}

        // If the cached provider is not cleared,
        // WalletConnect will default to the existing session
        // and does not allow to re-scan the QR code with a new wallet.
        // Depending on your use case you may want or not want not his behavior.
        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider, // required
                options: {
                    infuraId: "e0a3681de3704807b7decf022971810f", // required
                },
            },
        };

        var web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: true, // optional
            providerOptions, // required
        });

        await web3Modal.clearCachedProvider(); 
        
        context.commit('clearState');
        
       
    },
}

// mutations
const mutations = {
    setWeb3Modal(state, web3Modal) {
        state.web3Modal = web3Modal;
        //console.log(web3Modal, 'saving web3 modal');
        localStorage.setItem('web3Modal', JSON.stringify(web3Modal));
        //localStorage.setItem('web3Modal', web3Modal); 
    },
    setChainId(state, response) {
        state.chainId = response;
        localStorage.setItem('chainId', response);        
    },
    setChainData(state, response) {
        state.chainData = response;
        localStorage.setItem('chainData', response);        
    },
    setAccounts(state, response) {
        state.accounts = response;
        localStorage.setItem('accounts', response);        
    },
    setSelectedAccount(state, response) {
        state.selectedAccount = response;
        localStorage.setItem('selectedAccount', response);        
    },
    setShortAddress(state, response) {
        state.shortAddress = response;
        localStorage.setItem('shortAddress', response);
    },
    setArtmBalance(state, response) {
        state.artmBalance = response;
        localStorage.setItem('artmBalance', response);        
    },
    setWethBalance(state, response) {
        state.wethBalance = response;
        localStorage.setItem('wethBalance', response);        
    },
    setEthBalance(state, response) {
        state.ethBalance = response;
        localStorage.setItem('ethBalance', response);        
    },
    clearState(state) {
        localStorage.setItem('chainId', '');
        localStorage.setItem('chainData', '');
        localStorage.setItem('accounts', '');
        localStorage.setItem('selectedAccount', '');
        localStorage.setItem('shortAddress', '');
        localStorage.setItem('artmBalance', '');
        localStorage.setItem('wethBalance', '');
        localStorage.setItem('ethBalance', '');
        localStorage.setItem('web3Modal', '');
              
        state.chainId = '';
        state.chainData = '';
        state.accounts = '';
        state.selectedAccount = '';
        state.shortAddress = '';
        state.ethBalance = '';
        state.artmBalance = '';
        state.wethBalance = '';
        state.web3Modal = null;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
